import React     from "react";
import PropTypes from "prop-types";

export const Spinner = props => {
    const className = props.small ? "spinner-border spinner-border-sm" : "spinner-border";
    return (
        <div className="d-flex h-100 align-items-center justify-content-center my-auto">
            <div className={className} role="status">
                <span className="sr-only">Загрузка...</span>
            </div>
        </div>
    );
};

Spinner.protoTypes = {
    small: PropTypes.bool,
}
