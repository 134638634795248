import {Pages}                  from "./Pages";
import {StateHandler}           from "../../modules/StateHandler";
import {GROUP_STATE}            from "../Enums";
import ReactDOM                 from "react-dom";
import React                    from "react";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";
import {Comments}               from "../components/Comments";

export class GroupsDashboard extends Pages{

    initPageHandlers() {

        super.initPageHandlers();

        this.data = window.diaryJsData;

        let $document = $(document),
            stateHandler;

        if (this.data.templateIsInit) {

            stateHandler = new StateHandler({
                "states": {
                    open: GROUP_STATE.open,
                    close: GROUP_STATE.close,
                },
                "statesLabels": this.data.groupStatesLabel,
            });

        }

        this.initPageComponents();

    }

    initPageComponents() {

        ReactDOM.render(
            <EntitiesSearchDropList
                placeholder={"быстрый поиск по названию или ID группы"}
                entity={Entities.GROUPS}/>,
            document.getElementById('groups-search-form'),
        );

        let commentsCells = document.querySelectorAll("[data-comments]");
        for (let i = 0; i < commentsCells.length; i++) {
            let element = commentsCells[i];
            ReactDOM.render(
                <Comments
                    entityType={element.dataset.entityType}
                    entityId={element.dataset.entityId}
                />,
                element,
            );
        }


    }


}
