const axios = require('axios').default;

export function StateHandler(options) {
    'use strict';
    this.statesLabels = options.statesLabels;
    this.states = options.states;
    this.currentState = "";
    this.$spinner = null;
    this.$label = null;
    this.$button = null;
    this.$buttonContainer = null;
    this.$container = null;
    this.init();
}

StateHandler.prototype.init = function () {
    'use strict';

    if (!this.statesLabels || !this.states) {
        console.log("StateHandler не может быть запущен без параметров");
        return false;
    }

    this.refreshStateContainer = this.refreshStateContainer.bind(this);
    this.setState = this.setState.bind(this);

    $(document)
        .on('click.setGroupState', ".group_state button", this.setState);

};

StateHandler.prototype.setState = function (e) {

    e.preventDefault();
    e.stopPropagation();

    this.$button = $(e.target);
    this.$buttonContainer = this.$button.parents(".btn-container");
    this.$container = this.$button.parents(".group_state");
    this.currentState = this.$container.attr("data-group-state");
    this.$label = this.$container.find("span");
    this.$spinner = $('<span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>');

    let groupID = this.$container.attr("data-group-id"),
        data = {
            "ID": groupID,
            "state": (this.currentState === this.states.open) ? this.states.close : this.states.open,
        };

    this.$button.hide();
    this.$buttonContainer.append(this.$spinner);

    axios.put(`/api/groups/`,
        {
            element: data,
        },
    )
         .then((response) => {
             this.refreshStateContainer();
         })
         .catch((error) => {
             this.$spinner.remove();
             this.$buttonContainer.append(this.$button);
             throw new Error("Не удалось записать состояние группы!");
         });

};

StateHandler.prototype.refreshStateContainer = function () {
    this.currentState = (this.currentState === this.states.open) ? this.states.close : this.states.open;

    //set state in data attr of container
    this.$container.attr("data-group-state", this.currentState);

    this.$label
        .toggleClass(this.statesLabels.title_class[this.states.open])
        .toggleClass(this.statesLabels.title_class[this.states.close])
        .html(this.statesLabels.title_text[this.currentState]);
    this.$button
        .toggleClass(this.statesLabels.button_class[this.states.open])
        .toggleClass(this.statesLabels.button_class[this.states.close])
        .html(this.statesLabels.button_text[this.currentState])
        .show();

    this.$spinner.remove();
    this.$spinner = null;
    this.$buttonContainer.append(this.$button);
};
