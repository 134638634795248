export class Helpers {
    constructor() {
    }

    /**
     * возвращает список, где ключами будут ID элемента
     * для использования в компонентах и подстановки в Select
     * @param $arObjects
     */
    static getListForSelect($arObjects) {
        let outAr = [];
        if ($arObjects.hasOwnProperty("ID") || $arObjects.hasOwnProperty("id")){
            $arObjects.each((value)=>{
                outAr[value.ID] = value;
            })
        }
        return outAr;
    }

    /**
     * возвращает объект Date из русской даты dd.mm.yyyy
     * @param strDate
     * @returns {boolean|Date}
     */
    static getDateFromString(strDate) {
        if (strDate && strDate.length > 0) {
            const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            return new Date(strDate.replace(pattern, '$3-$2-$1'));
        }
        return false;
    }

    /**
     * конвертирует временную метку JS в миллисекундах в секунды
     * @param date
     * @returns {number}
     */
    static getTsInSeconds(date) {
        return Math.ceil(date.getTime() / 1000);
    }

    /**
     * возвращает дату в ISO приведённую к местному поясу
     * @param date
     * @returns {string}
     */
    static getLocalISOString(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        return (new Date(date.getTime() - tzOffset)).toISOString().slice(0, -1);
    }

    static serialiseObject(obj) {
        let pairs = [];
        for (let prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }
            if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                pairs.push(prop + '[]=' + Helpers.serialiseObject(obj[prop]));
                continue;
            }
            pairs.push(prop + '=' + obj[prop]);
        }
        return pairs.join('&');
    }
}
