import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {Spinner}                              from "../dom/Spinner";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {Utils}                                from "../Utils";
import {Helpers}                              from "../helpers/Helpers";


const axios = require('axios').default;

export const CommentForm = props => {
    let initElement = {
        "entity_type": props.entityType,
        "entity_id": props.entityId,
        "user_id": app.session.ID,
        "date": Helpers.getLocalISOString(new Date()),
        "text": "",
    };

    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };

        return [htmlElRef, setFocus];
    };
    const [element, setElement] = useState(initElement);
    const [textRef, setTextFocus] = useFocus();
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (textRef){
            setTextFocus()
        }
    },[]);

    const handleChange = (e) => {
        const {id, value} = e.target;
        setElement({...element, [id]: value});
    };

    const onCancel = (e) => {
        props.onCancel();
    };

    const handleSubmit = (event) => {
        setLoading(true);
        if (!element.text) {
            setErrors(errors => [...errors, "Не установлен комментарий!"]);
            setLoading(false);
            return false;
        }

        setElement({...element, "date": Helpers.getLocalISOString(new Date())});

        axios.put('/api/comments/save/',
            {
                element: {
                    "ID": element.id,
                    "entity_type": element.entity_type,
                    "entity_id": element.entity_id,
                    "user_id": element.user_id,
                    "date": element.date,
                    "text": element.text,
                },
            },
        )
             .then((response) => {
                 setLoading(false);
                 props.onSave();
             })
             .catch((error) => {
                 setLoading(false);
                 setErrors(errors => [...errors, "Не удалось сохранить данные!"]);
             });
    };

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <textarea className="form-control mb-2"
                      id={"text"}
                      name={"element[text]"}
                      rows={5}
                      required={true}
                      ref={textRef}
                      onChange={handleChange}
                      autoComplete={"off"}
                      value={element.text}></textarea>
            <div className="text-end">
                <button className="btn btn-success btn-extra-sm me-2"
                        title={"записать комментарий"}
                        onClick={handleSubmit}>
                    <i className="fas fa-check me-2"></i>Записать
                </button>
                <button className="btn btn-secondary btn-extra-sm"
                        title={"закрыть форму"}
                        onClick={onCancel}>
                    <i className="fas fa-times me-2"></i>Отменить
                </button>
            </div>

        </Fragment>
    );
};

CommentForm.propTypes = {
    entityTypeId: PropTypes.string,
    entityId: PropTypes.string,
    userId: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};
