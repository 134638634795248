import React, {Fragment, useState} from "react";
import PropTypes                   from "prop-types";

export const Comment = props => {
    const [element, setElement] = useState(props.element);

    return (
        <div className={"mb-2"}>
            {element.user ?
                <div>
                    <i className="far fa-user me-2"></i>
                    <a className="me-3"
                       href={`/users/${element.user.ID}/`}
                       title={"просмотреть данные пользователя"}>
                        {element.user.login}
                    </a>
                </div>
                :
                ""
            }
            <span className={"text-nowrap"}>
                <i className="far fa-calendar-alt me-2"></i>{element.dateDisplay}
            </span>
            <div>
                {element.text}
            </div>
        </div>
    );
};

Comment.propTypes = {
    element: PropTypes.object,
};
